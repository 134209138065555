<template>
    <YsPagination ref="node" :showFooter="false">
        <template #header>
            <el-button type="primary" @click.prevent="getList">查询</el-button>
            <el-button type="success" @click="editEvt()">新增类型</el-button>
        </template>
        <el-table :data="list" height="100%" style="width: 100%" border>
            <el-table-column label="序号" prop="seq" width="100" />
            <el-table-column label="名字" prop="name"/>
            <el-table-column label="图标" prop="icon" width="180">
                <template #default="scope">
                    <el-image style="width: 68px; height: 68px" :src="makeImagePath(scope.row.icon)" fit="cover" />
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
                <template #default="scope">
                    <el-button size="small" type="danger" @click="deleteEvt(scope.row)">删除</el-button>
                    <!-- <el-button size="small" type="primary" @click="editEvt(scope.row)">编辑</el-button> -->
                </template>
            </el-table-column>
        </el-table>


        <el-dialog v-model="showEdit" title="修改类型" center>
            <el-form :model="editObj" :rules="editRule" ref="formNode" status-icon>
                <el-form-item label="名字" :label-width="68" prop="name">
                    <el-input v-model="editObj.name" placeholder="输入类型名字" clearable/>
                </el-form-item>
                <el-form-item label="序号" :label-width="68" prop="seq">
                    <el-input-number v-model="editObj.seq" placeholder="输入序号" :min="1" :max="50" clearable/>
                </el-form-item>
                <el-form-item label="图标" :label-width="68" prop="icon">
                    <div class="img-box" v-if="!!editObj.icon">
                        <el-image style="width: 100px; height: 100px" :src="makeImagePath(editObj.icon)" fit="cover" />
                        <div class="tips">
                            <el-icon class="my-tips" @click="deleteIcon">
                                <Delete />
                            </el-icon>
                        </div>
                    </div>
                    <div class="addimage-box" @click="addImage" v-else>
                        <el-icon class="my-icon">
                            <Plus />
                        </el-icon>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="showEdit = false">关闭</el-button>
                    <el-button type="primary" @click="saveType">保存</el-button>
                </span>
            </template>
        </el-dialog>
    </YsPagination>
</template>

<script>
import { makeImagePath, scaleImage } from '../../utils/common'
import { deleteFileApi, fileApi } from '../../api/good'
import { typesAddApi, typesListApi ,typesDeleteApi} from '../../api/types'
import { ElMessage, ElMessageBox } from 'element-plus'


export default {
    data() {
        return {
            list: [],
            showEdit: false,
            editObj: {
                id: 0,
                name: '',
                seq: 1,
                icon: ''
            },
            editRule: {
                name: {required: true, message: '填写类型名字'},
                seq: {validator: (_, __, cb) => {
                    if (!!this.editObj.id) {
                        if (this.list.filter(oo => oo.seq == this.editObj.seq).length > 1) {
                            cb(new Error('序号不能重复'))
                        } else {
                            cb()
                        }
                    } else {
                        if (this.list.filter(oo => oo.seq == this.editObj.seq).length > 0) {
                            cb(new Error('序号不能重复'))
                        } else {
                            cb()
                        }
                    }
                }},
                icon: {required: true, message: '选择图片'}
            },
            makeImagePath
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            typesListApi().then(d => {
                if(d.meta?.code === 0) {
                    this.list = d.data
                }
            })
        },
        editEvt(obj) {
            if (!!obj) {
                this.editObj = {
                    id: obj.id,
                    name: obj.name,
                    seq: obj.seq,
                    icon: obj.icon
                }
            } else {
                this.editObj = {
                    id: 0,
                    name: '',
                    seq: 0,
                    icon: ''
                }
            }
            this.showEdit = true
        },
        async addImage() {
            let d = await scaleImage(530, 240)
            if(!d){
                return
            }
            let res = await fileApi({ file: [d] })
            if (res.meta.code == 0) {
                this.editObj.icon = res.data[0].fileId
            }
        },
        async deleteIcon() {
            let res = await deleteFileApi(this.editObj.icon)
            if (res.meta.code == 0) {
                this.editObj.icon = ''
            }
        },
        saveType() {
            this.$refs.formNode.validate().then(() => {
                typesAddApi(this.editObj).then(d => {
                    if (d.meta?.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: `保存成功！`,
                        })
                        this.showEdit = false
                        this.getList()
                    } else {
                        ElMessage({
                            type: 'error',
                            message: `保存失败！`,
                        })
                    }
                })
            }).catch(() => {})
        },
        deleteEvt(row) {
            console.log(row)
            // typesDeleteApi
            ElMessageBox.confirm(
            '你确定要删除该分类么？',
            'Warning', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
         }
         )
            .then(async () => {
               let res = await typesDeleteApi(row.id)
               if (res.meta.code == 0) {
                  this.$refs.node.queryFunc()
                  ElMessage({
                     type: 'success',
                     message: '删除成功！',
                  })
               }
            })
            .catch(() => {

            })
        }
    }
}
</script>

<style lang="less" scoped>

.addimage-box {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #4c4d4f;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.img-box {
    position: relative;

    >.tips {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        position: absolute;
        font-size: 24px;
        top: 0;
        left: 0;
        cursor: pointer;
        margin-right: 10px;

        .my-tips {
            display: none;
        }
    }

    .tips:hover {
        background-color: rgba(1, 1, 1, 0.5);

        >.my-tips {
            display: block;
        }
    }
}
</style>