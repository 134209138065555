import Ajax from "@/utils/request.js";
import {} from "@/config/user.cfg";
import { TYPES_URL } from "../config/user.cfg";

export function typesAddApi(data) {
    return Ajax({
        url: TYPES_URL,
        method: 'POST',
        data
    })
}

export function typesListApi() {
    return Ajax({
        url: TYPES_URL,
        method: 'GET'
    })
}

export function typesDeleteApi(id) {
    return Ajax({
        url: TYPES_URL + '/' + id,
        method: 'DELETE'
    })
}